






import Vue from "vue";

// Components
import Links from "@/components/Links.vue";
export default Vue.extend<any, any, any, any>({
  name: "Footer",

  components: { Links },
});
