






























import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  name: "Links",
  data: () => ({
    links: [
      {
        href: "#",
        text: "About Us",
      },
      {
        href: "#",
        text: "Licenses",
      },
    ],
  }),
});
